<template>
  <div class="wrapper padding-lr-sm">
    <div class="flex align-center justify-between margin-bottom-sm">
      <div>
        <el-button size="small" type="primary" round @click="needDialog = true">新增待办事项</el-button>
        <el-button size="small" type="warning" round @click="chooseDialog = true">代办选项管理</el-button>
      </div>
      <div class="flex align-center margin-left" style="width: 70%">
        <el-input size="small" v-model="miaoshu" placeholder="请输入任务描述"
                  class=" flex-sub margin-right-sm"></el-input>
        <el-select size="small" style="width: 100%" v-model="youxianji" placeholder="请选择优先级" clearable
                   class=" flex-sub margin-right-sm">
          <el-option
              v-for="item in levelList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-select size="small" style="width: 100%" v-model="leixing" placeholder="请选择任务类型" clearable
                   class=" flex-sub margin-right-sm">
          <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-select size="small" style="width: 100%" v-model="zhuangtai" placeholder="请选择任务状态" clearable
                   class=" flex-sub margin-right-sm">
          <el-option
              v-for="item in stateList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>


        <el-button size="small" type="primary" @click="hanldSearch">搜索</el-button>
      </div>

    </div>

    <el-table
        :data="needList"
        header-row-class-name="table-header"
        row-class-name="table-row"
        alig="center"
        border
        style="width: 100%"
    >
      <el-table-column width="70" type="index" align="center" label="序号"></el-table-column>
      <el-table-column label="任务描述" prop="miaoshu" width="300px">
        <template slot-scope="scope">
          <el-popover
              placement="top-start"
              title="任务描述"
              width="800"
              trigger="hover"
              :content="scope.row.miaoshu">
            <div class="ovHide2" slot="reference">{{ scope.row.miaoshu }}</div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="优先级" prop="youxianjiCn">
        <template slot-scope="scope">
          <div class="flex align-center justify-center">
            <el-tag :color="scope.row.color2" effect="dark">
              {{ scope.row.youxianjiCn }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="任务类型" prop="leixingCn">
        <template slot-scope="scope">
          <div class="flex align-center justify-center">
            <el-tag :color="scope.row.color1" effect="dark">
              {{ scope.row.leixingCn }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="任务状态" prop="zhuangtaiCn">
        <template slot-scope="scope">
          <div class="flex align-center justify-center">
            <el-tag :color="scope.row.color3" effect="dark">
              {{ scope.row.zhuangtaiCn }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="执行人" prop="zhixingren"></el-table-column>
      <el-table-column label="负责人" prop="fuzeren"></el-table-column>
      <el-table-column label="到期时间" prop="daoqishijian"></el-table-column>
      <el-table-column label="说明" prop="contentone" width="300px">
        <template slot-scope="scope">
          <el-popover
              placement="top-start"
              title="任务描述"
              width="800"
              trigger="hover"
              :content="scope.row.contentone">
            <div class="ovHide2" slot="reference">{{ scope.row.contentone }}</div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column width="150" label="操作">
        <template slot-scope="scope">
          <div class="table-operate">
            <div class="flex align-center justify-around margin-bottom-xs">

              <el-button size="small" type="primary" plain @click="editItem(scope.row)">编辑</el-button>
              <el-button size="small" type="danger" @click="deleteItem(scope.row.id)">删除</el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        v-if="needList.length > 0"
        class="page-all"
        background
        @current-change="getList"
        :current-page.sync="page"
        :page-size="limit"
        layout="prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>

    <!--新增修改代办信息-->
    <el-drawer
        title="代办信息设置"
        :visible.sync="needDialog"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        :before-close="cancelDialog"
    >
      <div class="padding-lr">
        <el-form :model="needForm"
                 label-width="80px"
                 :rules="rules" ref="needForm" class="add-form">
          <el-form-item label="任务描述" prop="miaoshu">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                v-model="needForm.miaoshu" placeholder="请填写任务描述"></el-input>
          </el-form-item>
          <el-form-item label="优先级" prop="youxianji">
            <el-select style="width: 100%" v-model="needForm.youxianji" placeholder="请选择优先级">
              <el-option
                  v-for="item in levelList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务类型" prop="leixing">

            <el-select style="width: 100%" v-model="needForm.leixing" placeholder="请填选择任务类型">
              <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务状态" prop="zhuangtai">
            <el-select style="width: 100%" v-model="needForm.zhuangtai" placeholder="请填选择任务状态">
              <el-option
                  v-for="item in stateList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="执行人" prop="zhixingren">
            <el-input v-model="needForm.zhixingren" placeholder="请填写执行人"></el-input>
          </el-form-item>
          <el-form-item label="负责人" prop="fuzeren">
            <el-input v-model="needForm.fuzeren" placeholder="请填写负责人"></el-input>
          </el-form-item>
          <el-form-item label="到期时间" prop="daoqishijian">
            <el-date-picker align="center"
                            style="width: 100%"
                            v-model="needForm.daoqishijian"
                            type="date"
                            placeholder="请选择到期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="说明" prop="contentone">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                v-model="needForm.contentone" placeholder="请填写说明"></el-input>
          </el-form-item>
          <el-form-item label-width="0" class="flex justify-around">
            <el-button class="submit-btn" type="primary" @click="submitForm('needForm')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-drawer>
    <!--        代办选项管理-->
    <el-dialog
        title="待办选项管理"
        :visible.sync="chooseDialog"
        width="1200px"
        :before-close="handleClose">
      <div class="flex align-start justify-around">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>优先级</span>
            <el-button style="float: right; padding: 3px 0" type="text"
                       @click="addChooseItem(1)">新增
            </el-button>
          </div>
          <div v-for="item in levelList" :key="item.id"
               class="flex align-center justify-between margin-bottom-sm">
            <el-button size="small" type="primary" plain>{{ item.name }}</el-button>
            <div>
              <el-button size="small" type="primary" icon="el-icon-edit" circle
                         @click="editChooseItem(1,item.name,item.id)"></el-button>
              <el-button size="small" type="danger" icon="el-icon-delete" circle
                         @click="delChooseItem(1,item.id)"
              ></el-button>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>任务类型</span>
            <el-button style="float: right; padding: 3px 0" type="text"
                       @click="addChooseItem(2)"
            >新增
            </el-button>
          </div>
          <div v-for="item in typeList" :key="item.id"
               class="flex align-center justify-between margin-bottom-sm">
            <el-button size="small" type="primary" plain>{{ item.name }}</el-button>
            <div>
              <el-button size="small" type="primary" icon="el-icon-edit" circle
                         @click="editChooseItem(2,item.name,item.id)"></el-button>
              <el-button size="small" type="danger" icon="el-icon-delete" circle
                         @click="delChooseItem(2,item.id)"
              ></el-button>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>任务状态</span>
            <el-button style="float: right; padding: 3px 0" type="text"
                       @click="addChooseItem(3)">新增
            </el-button>
          </div>
          <div v-for="item in stateList" :key="item.id"
               class="flex align-center justify-between margin-bottom-sm">
            <el-button size="small" type="primary" plain>{{ item.name }}</el-button>
            <div>
              <el-button size="small" type="primary" icon="el-icon-edit" circle
                         @click="editChooseItem(3,item.name,item.id)"></el-button>
              <el-button size="small" type="danger" icon="el-icon-delete" circle
                         @click="delChooseItem(3,item.id)"
              ></el-button>
            </div>
          </div>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {addOrEditTask, addOrEditTaskZiDian, delTask, delTaskZiDian, taskList, taskZiDianList} from "@/api/task";
import rules from "@/utils/rules";
import {zhDate} from "@/utils";

export default {
  name: "needList",
  data() {
    return {
      color: ['#77a9de', '#67c23a', '#7e7ed7', '#e6a23c', '#f56c6c', '#35495e', '#ad615a', '#35aaaf', '#ba62c4', '#e6a23c'],
      miaoshu: '',
      youxianji: '',
      leixing: '',
      zhuangtai: '',
      page: 1,
      limit: 10,
      total: 0,
      needList: [],
      needDialog: false,
      chooseDialog: false,
      levelList: [],
      typeList: [],
      stateList: [],
      needForm: {
        miaoshu: '',
        youxianji: '',
        leixing: '',
        zhuangtai: '',
        zhixingren: '',
        fuzeren: '',
        daoqishijian: '',
        contentone: '',
      },
      rules: {
        miaoshu: rules.mustInput,
      },
      setId: 0,
      setItem: {},
    }
  },
  async created() {
    this.$emit("setIndex", this.$route.path, "待办事宜", false);

    await this.getChooseList(0)
    await this.getList()
  },
  components: {},
  methods: {
    //  添加选项
    addChooseItem(type) {
      this.$prompt('请输入', '新增选项', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({value}) => {
        if (!value) return this.$message.error('请输入内容')
        this.addChoose(type, value, 0)
      })
    },
    //  修改选项
    editChooseItem(type, str, id) {
      this.$prompt('请输入', '新增选项', {
        inputValue: str,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({value}) => {
        if (!value) return this.$message.error('请输入内容')
        this.addChoose(type, value, id)
      })
    },
    async addChoose(type, name, id) {
      await addOrEditTaskZiDian(id, type, name)
      await this.getChooseList(type)
      await this.$message.success('操作成功')
    },
    //  删除选项
    delChooseItem(type, id) {
      this.$confirm('此操作将删除改选项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delChoose(type, id)
      })
    },
    async delChoose(type, id) {
      await delTaskZiDian(id, type)
      await this.getChooseList(type)
      await this.$message.success('操作成功')
    },
    //  获取选项
    async getChooseList(type) {
      if (type === 0 || type === 1) {
        const res1 = await taskZiDianList(1)
        this.levelList = res1.data
      }
      if (type === 0 || type === 2) {
        const res2 = await taskZiDianList(2)
        this.typeList = res2.data
      }
      if (type === 0 || type === 3) {
        const res3 = await taskZiDianList(3)
        this.stateList = res3.data
      }
    },
    //  关闭选项管理
    handleClose() {
      this.chooseDialog = false
    },
    //  提交代办信息
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await addOrEditTask(
          this.setId,
          this.needForm.miaoshu,
          this.needForm.youxianji,
          this.needForm.leixing,
          this.needForm.zhuangtai,
          this.needForm.zhixingren,
          this.needForm.fuzeren,
          this.needForm.daoqishijian ? zhDate(new Date(this.needForm.daoqishijian)) : '',
          this.needForm.contentone,
      )

      if (_state.status === 200) {
        this.cancelDialog()
      } else {
        this.$message.success(_state.info)
      }
    },
    //  编辑
    editItem(info) {
      let obj = JSON.parse(JSON.stringify(info))
      this.needForm = {
        miaoshu: obj.miaoshu,
        youxianji: obj.youxianji ? obj.youxianji : '',
        leixing: obj.leixing ? obj.leixing : '',
        zhuangtai: obj.zhuangtai ? obj.zhuangtai : '',
        zhixingren: obj.zhixingren,
        fuzeren: obj.fuzeren,
        daoqishijian: obj.daoqishijian ? new Date(obj.daoqishijian) : '',
        contentone: obj.contentone,
      }
      this.setId = obj.id;
      this.needDialog = true;
    },
    //  代办信息编辑后回调
    cancelDialog() {
      this.setId = 0;
      this.setItem = {};
      this.$refs['needForm'].resetFields();
      this.$refs['needForm'].clearValidate();
      this.needDialog = false;
      this.getList()
    },

    //  删除
    deleteItem(id) {
      this.$confirm('您确定要删除该代办信息吗？', "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "msg-btn",
        confirmButtonClass: "msg-btn",
        center: true
      }).then(() => {
        this.sureDelete(id);
      });
    },
    async sureDelete(id) {
      let _state = await delTask(id);
      if (this.needList.length === 1 && this.page > 1) {
        this.page--;
      }
      await this.getList();
      this.$message.success(_state.info);
    },
    //  搜索
    hanldSearch() {
      this.page = 1
      this.needList = []
      this.getList()
    },
    //  获取打分表列表
    async getList() {
      let searchSTime = ''
      let searchETime = ''
      const res = await taskList(this.page, this.limit, this.miaoshu, this.youxianji, this.leixing, this.zhuangtai, searchSTime, searchETime)

      if (res.data) {
        res.data.map(item => {

          let _index1 = this.typeList.findIndex(v => v.id === item.leixing)
          let _index2 = this.levelList.findIndex(v => v.id === item.youxianji)
          let _index3 = this.stateList.findIndex(v => v.id === item.zhuangtai)
          item.color1 = this.color[_index1]
          item.color2 = this.color[_index2]
          item.color3 = this.color[_index3]
        })
      }


      this.needList = res.data ? res.data : []
      this.total = res.total_number
    }
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.box-card {
  width: 350px;
}
</style>
