import {qspost} from '@/plugins/axios'

/*
 * 待办列表
 * @constructor jack
 * */
export const taskList = (page, limit, miaoshu, youxianji,leixing,zhuangtai, searchSTime, searchETime) => qspost('/index/Task/taskList', {
    page, limit, miaoshu, youxianji,leixing,zhuangtai, searchSTime, searchETime
})

/*
 * 待办详情
 * @constructor jack
 * */
export const taskShow = (id) => qspost('/index/Task/taskList', {id})

/*
 * 添加或修改待办
 * @constructor jack
 * */
export const addOrEditTask = (id, miaoshu, youxianji, leixing, zhuangtai, zhixingren, fuzeren, daoqishijian, contentone, contenttwo) => qspost('/index/Task/addOrEditTask', {
    id,
    miaoshu,
    youxianji,
    leixing,
    zhuangtai,
    zhixingren,
    fuzeren,
    daoqishijian,
    contentone,
    contenttwo
})

/*
 * 删除待办
 * @constructor jack
 * */
export const delTask = (id) => qspost('/index/Task/delTask', {id})

/*
 * 代办选项获取
 * @constructor jack
 * */
export const taskZiDianList = (type) => qspost('/index/Task/taskZiDianList', {type})


/*
 * 代办选项新增/修改
 * @constructor jack
 * */
export const addOrEditTaskZiDian = (id,type,name) => qspost('/index/Task/addOrEditTaskZiDian', {id,type,name})


/*
 * 代办选项获取
 * @constructor jack
 * */
export const delTaskZiDian = (id) => qspost('/index/Task/delTaskZiDian', {id})
